<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <router-link to="/support/bulk-messages-history" class="float-right mb-2 btn btn-success">
          <i class="fas fa-history"></i> View bulk sms history
        </router-link>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="handleSubmit">
              <user-placeholders></user-placeholders>
              <div class="form-group">
                <label for="message"> Type message here </label>
                <textarea name="" id="message" cols="30" rows="10" placeholder="" class="form-control"
                          v-model="form.message"
                          required
                ></textarea>
              </div>

              <div class="form-group">
                <b-form-checkbox
                    v-model="form.usePushNotification"
                    switch
                >
                  Use push notification
                </b-form-checkbox>
              </div>

              <div class="form-group">
                <b-form-checkbox
                    v-model="form.useSMS"
                    switch
                >
                  Use SMS
                </b-form-checkbox>
              </div>

              <div class="form-group">
                <label>Select drivers</label>
                <b-card>
                  <b-card-text>
                    <template  v-for="driver in drivers">
                      <a href="#" @click.prevent="removeDriver(driver['id'])">
                        <span class="badge badge-pill badge-soft-primary p-2 mr-2"> {{driver['name']}} {{driver['other_names']}} <i class="fas fa-trash-alt ml-2 mr-1"></i> </span>
                      </a>
                    </template>

                  </b-card-text>
                  <a href="#" class="card-link" @click.prevent="$bvModal.show('drivers')">Click to include drivers</a>
                </b-card>
                <!--          <share-driver-list @on-drivers-selected="onDriversSelected"></share-driver-list>-->
                <shared-drivers @on-drivers-selected="onDriverSelected" ></shared-drivers>
              </div>

              <div class="form-group">
                <button type="submit" class="btn btn-dark"> Broadcast message</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";
import SharedDrivers from '../drivers/share-driver-list'
import {confirm} from "../../../../utils/functions";
import UserPlaceholders from "../../../../components/UserPlaceholders";
import {notificationsServices} from "../../../../apis/notifications.services";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title}`,
    };
  },
  components: {
    SharedDrivers,
    UserPlaceholders
  },
  data() {
    return {
      title: "Starter",
      form: {
        useSMS: false,
        usePushNotification: true,
        message: '',
      },
      drivers: []
    };
  },
  methods: {

    onDriverSelected(drivers){
      console.log('selected drivers', drivers)
      this.drivers = drivers;
    },
    removeDriver(driverId){
      const findIndex = this.drivers.findIndex(e => e['id'] === driverId)
      this.drivers.splice(findIndex,1)
    },

    handleSubmit(){

      if(this.drivers.length === 0){
        this.$store.dispatch('error', {message: 'Please select at least one driver', showSwal: true})
        return;
      }

      if(!this.form.useSMS && !this.form.usePushNotification){
        this.$store.dispatch('error', {message: 'Please enable at least sms or push notification', showSwal: true})
        return;
      }

      const payload = {
        'recipients': this.drivers,
        'message': this.form.message,
        'useSms': this.form.useSMS,
        'usePush': this.form.usePushNotification
      }

      const confirmMessage = 'Send message to ' + this.drivers.length + ' drivers(s)'
      confirm(confirmMessage, () => {

        this.$store.dispatch('showLoader')
        notificationsServices.sendCampaignNotification(payload).then((data) => {
          this.$store.dispatch('hideLoader')

          if(!data.status){
            this.$store.dispatch('error', {message: data.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: 'Processing messages', showSwal: true})
          this.drivers = []
          this.form.message = ''
          this.form.usePushNotification = true
          this.form.useSMS = false


        })


      })


    }

  },
  created() {
    this.$store.dispatch('setPageTitle', 'Send Bulk Messages')
  }
};
</script>

